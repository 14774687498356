// import * as mo from 'moment'
import { StoBase } from './Lib'
// import * as mo from 'moment'
import __ from '../util'

export default class Niche extends StoBase {
  constructor (cx, token) {
    super('Niche', cx, '39417e8e-eac4-4b1c-a0dd-33c1bd59ed22')
    this.token = token
    this.post = this.post.bind(this)
    this.getPending = this.getPending.bind(this)
  }

  async post (string) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'niche',
        method: 'POST',
        data: { ...string }
      },
      token
    )
    return pld
  }

  async getPending (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'niche',
        method: 'GET',
        params: { ...data }
      },
      token
    )
    return pld
  }

}
