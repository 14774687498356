// import * as mo from 'moment'
import { StoBase } from './Lib'
// import * as mo from 'moment'
import __ from '../util'

export default class Topic extends StoBase {
  constructor (cx, token) {
    super('Topic', cx, '771f44c6-6f8b-45eb-9db0-c6a9db07779f')
    this.token = token
    this.getPending = this.getPending.bind(this)
    this.post = this.post.bind(this)
    this.postArticle = this.postArticle.bind(this)
    this.postTopicalMap = this.postTopicalMap.bind(this)
    this.postTopicalMapSEOApi = this.postTopicalMapSEOApi.bind(this)
    this.getTopicalMapCsvs = this.getTopicalMapCsvs.bind(this)
    this.getTopicalMapCsvsSeo = this.getTopicalMapCsvsSeo.bind(this)
  }

  async getPending (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic',
        method: 'GET',
        params: { ...data }
      },
      token
    )
    return pld
  }

  async getTopicalMapCsvs (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic/csv',
        method: 'GET',
        params: { ...data }
      },
      token
    )
    return pld
  }

  async getTopicalMapCsvsSeo (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic/csv/seo',
        method: 'GET',
        params: { ...data }
      },
      token
    )
    return pld
  }

  async post (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic',
        method: 'POST',
        data: { ...data }
      },
      token
    )
    return pld
  }

  async postArticle (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'article-topic',
        method: 'POST',
        data: { ...data }
      },
      token
    )
    return pld
  }

  async postTopicalMap (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic/map',
        method: 'POST',
        data: { ...data }
      },
      token
    )
    return pld
  }
  

  async postTopicalMapSEOApi (data) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'topic/map/seogen',
        method: 'POST',
        data: { ...data }
      },
      token
    )
    return pld
  }

}
