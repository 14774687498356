import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class TopicalMapView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      aiInstance: 'gpt-3.5-turbo',
      language: 'German',
      topic: '',
      numberTopics: 10,
      csvs: [],
      csvsSeo: []
    }
    this.state = {
      ...this.reset
    }
    this.postTopicalMap = this.postTopicalMap.bind(this)
    this.postTopicalMapSEOApi = this.postTopicalMapSEOApi.bind(this)
    this.getTopicalMapCsvs = this.getTopicalMapCsvs.bind(this)
    this.getTopicalMapCsvsSeo = this.getTopicalMapCsvsSeo.bind(this)
  }

  async postTopicalMapSEOApi (event, csv) {
    this.setState({ loading: true })
    try {
      await this.cx.topic.postTopicalMapSEOApi({
        email: __.getJsonSto('core').loginemail,
        csv
      })
      this.getTopicalMapCsvsSeo()
      this.setState({loading: false, snack: 'SEO API CSV created!'})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async postTopicalMap () {
    this.setState({ loading: true })
    try {
      await this.cx.topic.postTopicalMap({
        email: __.getJsonSto('core').loginemail,
        aiInstance: this.state.aiInstance,
        language: this.state.language,
        topic: this.state.topic,
        numberTopics: this.state.numberTopics
      })
      //TODO LIVE this.setState({...this.reset, snack: 'Generating csv in background...'})
      this.setState({snack: 'Generating csv in background...', loading: false})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async getTopicalMapCsvs () {
    this.setState({ loading: true })
    let csvs
    try {
      [csvs] = await Promise.all([
        this.cx.topic.getTopicalMapCsvs({email: __.getJsonSto('core').loginemail})
      ])
      this.setState({
        csvs,
        loading: false
      })
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  async getTopicalMapCsvsSeo () {
    this.setState({ loading: true })
    let csvsSeo
    try {
      [csvsSeo] = await Promise.all([
        this.cx.topic.getTopicalMapCsvsSeo({email: __.getJsonSto('core').loginemail})
      ])
      this.setState({
        csvs: this.state.csvs.map(row => {
          return {
            ...row,
            seoCsv: csvsSeo.find(seo => row.name.replace('topicalmap-csv/','') === seo.name.replace('topicalmap-with-seo-csv/',''))?.name || undefined,
            seoCsvSizeMb: csvsSeo.find(seo => row.name.replace('topicalmap-csv/','') === seo.name.replace('topicalmap-with-seo-csv/',''))?.sizeMb || undefined
          }
        }),
        csvsSeo,
        loading: false
      })
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'topicalmap'))

    await Promise.all([
     this.getTopicalMapCsvs(),
   ])
   this.getTopicalMapCsvsSeo()
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      console.log(this.state)
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noDash />
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Typography variant='h2' align='center'>
              Generate Topical Map
              <PostAddIcon />
            </Typography>
            <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15}}
            >
              <FormControl fullWidth>
                <InputLabel id="ai-instance-label">Ai Instance</InputLabel>
                <Select
                  labelId="ai-instance-label"
                  id="ai-instance"
                  value={this.state.aiInstance}
                  label="Ai Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({aiInstance: evt.target.value})}
                >
                  <MenuItem value={'gpt-3.5-turbo'}>OpenAi v3.5 Turbo</MenuItem>
                  <MenuItem value={'gpt-4'}>OpenAi v4</MenuItem>
                  <MenuItem value={'gpt-4-turbo'}>OpenAi v4 Turbo</MenuItem>
                  <MenuItem value={'gpt-4o'}>OpenAi v4 Omni</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="ai-language-label">Language</InputLabel>
                <Select
                  labelId="ai-language-label"
                  id="ai-language"
                  value={this.state.language}
                  label="Language"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({language: evt.target.value})}
                >
                  <MenuItem value={'English'}>English</MenuItem>
                  <MenuItem value={'German'}>German</MenuItem>
                  <MenuItem value={'Swedish'}>Swedish</MenuItem>
                  <MenuItem value={'Spanish'}>Spanish</MenuItem>
                  <MenuItem value={'Dutch'}>Dutch</MenuItem>
                  <MenuItem value={'French'}>French</MenuItem>
                  <MenuItem value={'Italian'}>Italian</MenuItem>
                  <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                  <MenuItem value={'Arabic'}>Arabic</MenuItem>
                  <MenuItem value={'Polish'}>Polish</MenuItem>
                </Select>
              </FormControl>

              <TextField 
                fullWidth 
                id="topic-name" 
                label="Topic"
                variant="outlined" 
                placeholder="Cars"
                style={{marginBottom: 20}}
                value={this.state.topic}
                onChange={evt => this.setState({topic: evt.target.value})}
              />

              <TextField  
                fullWidth 
                id="niche-numberTopics"
                label="Number of Topics"
                variant="outlined"
                placeholder="10"
                style={{marginBottom: 20}}
                value={this.state.numberTopics}
                onChange={evt => this.setState({numberTopics: evt.target.value})}
              />

              <Button
                fullWidth
                variant='contained'
                color='primary'
                // className={this.props.classes.loginButton}
                onClick={async event => this.postTopicalMap(event)}
                disabled={this.state.topic.length < 3}
              >
                Generate to CSV
              </Button>
            </Paper>
          </Grid>
        </Grid>
        {this.state.csvs && this.state.csvs.length === 0 && <Grid container spacing={0} justifyContent='center' style={{paddingTop: 80}}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant='body1' align='center'>
              No CSVs found.... come back later
            </Typography>
          </Grid>
        </Grid>}
        {this.state.csvs && this.state.csvs.length > 0 && <Grid container spacing={0} justifyContent='center' style={{paddingTop: 80}}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant='h2' align='center'>
              Finished CSVs
            </Typography>
            {this.state.csvs.map((csv, index) => (
              <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15, marginBottom: 20}}
              key={index}
              >
                <Typography variant='body1' style={{display:'block', marginBottom: 20}}>
                  Name: {csv.seoCsv || csv.name}
                  <span style={{float:'right'}}>
                    Created: {mo(csv.lastModified).format('YYYY-MM-DD')}
                    <br />
                    Size: {csv.seoCsvSizeMb || csv.sizeMb} MB
                  </span>
                </Typography>
                {!csv.seoCsv && <Button 
                  variant='outlined'
                  href={csv.url}
                  download
                >
                    Download CSV
                  </Button>}
                {csv.seoCsv && <Button
                  variant='outlined'
                  href={`//cdn.creativeblogai.com/${csv.seoCsv}`}
                  download
                >
                    Download SEO CSV
                  </Button>}
                {!csv.seoCsv && <Button
                    variant='outlined'
                    onClick={async event => this.postTopicalMapSEOApi(event, csv.name)}
                    style={{marginLeft: 40}}
                  >
                    Compute SEO Api
                  </Button>}
              </Paper>
            ))}
          </Grid>
        </Grid>}
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(TopicalMapView)
