// import * as mo from 'moment'
import { StoBase } from './Lib'
import * as mo from 'moment'
import __ from '../util'

export default class Dash extends StoBase {
  constructor (cx, token) {
    super('Dash', cx, 'ab56dfa7-3363-4ff1-9838-b894a59d9687')
    this.token = token
    this.postArticle = this.postArticle.bind(this)
    this.postBuddySelly = this.postBuddySelly.bind(this)
    this.postSekloan = this.postSekloan.bind(this)
    this.postCsv = this.postCsv.bind(this)
  }

  async postArticle (article) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'article',
        method: 'POST',
        data: { ...article, token }
      },
      token
    )
    return pld
  }

  async postBuddySelly (article) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'article/buddyselly',
        method: 'POST',
        data: { ...article, token }
      },
      token
    )
    return pld
  }

  async postSekloan (article) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'article/sekloan',
        method: 'POST',
        data: { ...article, token }
      },
      token
    )
    return pld
  }

  async postCsv (article) {
    const rqst = this.rqst
    const token = this.token

    const pld = await rqst(
      {
        url: 'article/csv',
        method: 'POST',
        data: { ...article, token }
      },
      token
    )
    return pld
  }

}
