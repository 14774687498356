import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
// import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { withStyles } from '@mui/styles'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class CsvGen extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      wpInstance: 0,
      aiInstance: 'gpt-3.5-turbo',
      wpArticleStatus: 'draft',
      language: 'German',
      articleCsv: ''
    }
    this.state = {
      ...this.reset
    }
    this.postCsv = this.postCsv.bind(this)
  }

  async postCsv () {
    this.setState({ loading: true })
    try {
      await this.cx.dash.postCsv({
        email: __.getJsonSto('core').loginemail,
        wpInstance: this.state.wpInstance,
        wpArticleStatus: this.state.wpArticleStatus,
        aiInstance: this.state.aiInstance,
        language: this.state.language,
        articleCsv: this.state.articleCsv
      })
      //TODO LIVE this.setState({...this.reset, snack: 'Generating article in background...'})
      this.setState({snack: 'Generating articles in background...', loading: false})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'csvgen'))

    /*await Promise.all([
     this.getMembers()
   ])*/
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noDash />
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Typography variant='h2' align='center'>
              Generate CSV Articles
              <PostAddIcon />
            </Typography>
            <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15}}
            >
              <FormControl fullWidth>
                <InputLabel id="ai-instance-label">Ai Instance</InputLabel>
                <Select
                  labelId="ai-instance-label"
                  id="ai-instance"
                  value={this.state.aiInstance}
                  label="Ai Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({aiInstance: evt.target.value})}
                >
                  <MenuItem value={'gpt-3.5-turbo'}>OpenAi v3.5 Turbo</MenuItem>
                  <MenuItem value={'gpt-4'}>OpenAi v4</MenuItem>
                  <MenuItem value={'gpt-4-turbo'}>OpenAi v4 Turbo</MenuItem>
                  <MenuItem value={'gpt-4o'}>OpenAi v4 Omni</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="ai-language-label">Language</InputLabel>
                <Select
                  labelId="ai-language-label"
                  id="ai-language"
                  value={this.state.language}
                  label="Language"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({language: evt.target.value})}
                >
                  <MenuItem value={'English'}>English</MenuItem>
                  <MenuItem value={'German'}>German</MenuItem>
                  <MenuItem value={'Spanish'}>Spanish</MenuItem>
                  <MenuItem value={'Swedish'}>Swedish</MenuItem>
                  <MenuItem value={'Dutch'}>Dutch</MenuItem>
                  <MenuItem value={'French'}>French</MenuItem>
                  <MenuItem value={'Italian'}>Italian</MenuItem>
                  <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                  <MenuItem value={'Arabic'}>Arabic</MenuItem>
                  <MenuItem value={'Polish'}>Polish</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="wp-instance-label">WP Instance</InputLabel>
                <Select
                  labelId="wp-instance-label"
                  id="wp-instance"
                  value={this.state.wpInstance}
                  label="WP Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({wpInstance: evt.target.value})}
                >
                  {__.getJsonSto('core').wpInstance.map((wp, i) => (
                    <MenuItem value={i}>
                      {wp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="wp-article-status-label">WP Article Status</InputLabel>
                <Select
                  labelId="wp-article-status-label"
                  id="wp-article-status"
                  value={this.state.wpArticleStatus}
                  label="WP Article Status"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({wpArticleStatus: evt.target.value})}
                >
                  <MenuItem value={'draft'}>Draft</MenuItem>
                  <MenuItem value={'publish'}>Publish</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="article-csv-input"
                label="Article CSV"
                multiline
                fullWidth
                maxRows={5}
                onChange={evt => this.setState({articleCsv: evt.target.value})}
                style={{marginBottom: 20}}
                placeholder='topic,translation,description,searchIntent,mainTopic,subTopic,thirdTopic,keyword,spell,location_code,language_code,competition,competition_index,search_volume,low_top_of_page_bid,high_top_of_page_bid,cpc,searchPerYear2023,searchPerYear2024'
              />

              <Button
                fullWidth
                variant='contained'
                color='primary'
                // className={this.props.classes.loginButton}
                onClick={async event => this.postCsv(event)}
                disabled={this.state.articleCsv.length < 3}
              >
                Post to WP
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(CsvGen)
