import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
// import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify'
import { withStyles } from '@mui/styles'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class NicheView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      aiInstance: 0,
      wpInstance: 0,
      wpArticleStatus: 'draft',
      useClickbait: 0,
      niche: '',
      language: '',
      numberCategories: 30,
      numberTopics: 10,
      pendingNiches: [],
      selectedNiches: []
    }
    this.state = {
      ...this.reset
    }
    this.postNiche = this.postNiche.bind(this)
    this.postTopic = this.postTopic.bind(this)
    this.getPendingNiches = this.getPendingNiches.bind(this)
    this.handleGoTopic = () => this.props.history.push('/topic')
  }

  async postNiche () {
    this.setState({ loading: true })
    let niches, pendingNiches
    try {
      [niches, pendingNiches] = await Promise.all([
        this.cx.niche.post({
          niche: this.state.niche,
          aiInstance: this.state.aiInstance,
          wpInstance: this.state.wpInstance,
          wpArticleStatus: this.state.wpArticleStatus,
          useClickbait: this.state.useClickbait,
          language: this.state.language,
          numberCategories: this.state.numberCategories,
          numberTopics: this.state.numberTopics,
          email: __.getJsonSto('core').loginemail // TODO move to token
        }),
        this.cx.niche.getPending({email: __.getJsonSto('core').loginemail})
      ])
      
      this.setState({...this.reset, pendingNiches, selectedNiches: pendingNiches, snack: 'Generating niches in background...'})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }

  async postTopic (nicheIndex) {
    this.setState({ loading: true })

    try {
      await Promise.all([
        this.cx.topic.post({
          niche: this.state.selectedNiches[nicheIndex].niche,
          language: this.state.selectedNiches[nicheIndex].language,
          categories: this.state.selectedNiches[nicheIndex].categories,
          aiInstance: this.state.selectedNiches[nicheIndex].aiInstance,
          numberCategories: this.state.selectedNiches[nicheIndex].numberCategories,
          numberTopics: this.state.selectedNiches[nicheIndex].numberTopics,
          useClickbait: this.state.selectedNiches[nicheIndex].useClickbait,
          wpArticleStatus: this.state.selectedNiches[nicheIndex].wpArticleStatus,
          wpInstance: this.state.selectedNiches[nicheIndex].wpInstance,
          email: __.getJsonSto('core').loginemail // TODO move to token
        })
      ])

      const pendingNiches = await this.cx.niche.getPending({email: __.getJsonSto('core').loginemail})
      
      this.setState({...this.reset, pendingNiches, snack: 'Generating topics in background...'})
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  
    
  }

  async getPendingNiches () {
    let pendingNiches
    try {
      [pendingNiches] = await Promise.all([
        this.cx.niche.getPending({email: __.getJsonSto('core').loginemail})
      ])
      this.setState({
        pendingNiches,
        selectedNiches: pendingNiches
      })
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'niche'))

    await Promise.all([
     this.getPendingNiches({email: __.getJsonSto('core').loginemail})
    ])
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noNiche />
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Typography variant='h2' align='center'>
              Generate Niche
              <FormatAlignJustifyIcon />
            </Typography>
            <Paper elevation={10} style={{padding: 15, marginBottom: 20}}>
              <TextField
                fullWidth 
                id="niche-niche"
                label="Niche"
                variant="outlined"
                placeholder="hunting dog"
                style={{marginBottom: 20}}
                value={this.state.niche}
                onChange={evt => this.setState({niche: evt.target.value})}
              />

              <FormControl fullWidth>
                <InputLabel id="ai-instance-label">Ai Instance</InputLabel>
                <Select
                  labelId="ai-instance-label"
                  id="ai-instance"
                  value={this.state.aiInstance}
                  label="Ai Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({aiInstance: evt.target.value})}
                >
                  {__.cfg('aiInstances').map((name, i) => (
                    <MenuItem value={i} key={i}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="wp-instance-label">WP Instance</InputLabel>
                <Select
                  labelId="wp-instance-label"
                  id="wp-instance"
                  value={this.state.wpInstance}
                  label="WP Instance"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({wpInstance: evt.target.value})}
                >
                  {__.getJsonSto('core').wpInstance.map((wp, i) => (
                    <MenuItem value={i} key={i}>
                      {wp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="wp-article-status-label">WP Article Status</InputLabel>
                <Select
                  labelId="wp-article-status-label"
                  id="wp-article-status"
                  value={this.state.wpArticleStatus}
                  label="WP Article Status"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({wpArticleStatus: evt.target.value})}
                >
                  <MenuItem value={'draft'}>Draft</MenuItem>
                  <MenuItem value={'publish'}>Publish</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="use-clickbait-label">Use Clickbait</InputLabel>
                <Select
                  labelId="use-clickbait-label"
                  id="use-clickbait"
                  value={this.state.useClickbait}
                  label="Use Clickbait"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({useClickbait: evt.target.value})}
                >
                  <MenuItem value={0}>No</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="language-label">Language</InputLabel>
                <Select
                  labelId="language-label"
                  id="niche-language"
                  value={this.state.language}
                  label="Language"
                  style={{marginBottom: 20}}
                  onChange={evt => this.setState({language: evt.target.value})}
                >
                  <MenuItem value={'English'}>English</MenuItem>
                  <MenuItem value={'German'}>German</MenuItem>
                  <MenuItem value={'Spanish'}>Spanish</MenuItem>
                  <MenuItem value={'Swedish'}>Swedish</MenuItem>
                  <MenuItem value={'Dutch'}>Dutch</MenuItem>
                  <MenuItem value={'French'}>French</MenuItem>
                  <MenuItem value={'Italian'}>Italian</MenuItem>
                  <MenuItem value={'Portuguese'}>Portuguese</MenuItem>
                  <MenuItem value={'Arabic'}>Arabic</MenuItem>
                  <MenuItem value={'Polish'}>Polish</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth 
                id="niche-numberCategories"
                label="Number of Categories"
                variant="outlined"
                placeholder="30"
                style={{marginBottom: 20}}
                value={this.state.numberCategories}
                onChange={evt => this.setState({numberCategories: evt.target.value})}
              />

              <TextField
                fullWidth 
                id="niche-numberTopics"
                label="Number of Topics"
                variant="outlined"
                placeholder="10"
                style={{marginBottom: 20}}
                value={this.state.numberTopics}
                onChange={evt => this.setState({numberTopics: evt.target.value})}
              />

              <Button
                fullWidth
                variant='contained'
                color='primary'
                // className={this.props.classes.loginButton}
                onClick={async event => this.postNiche(event)}
                disabled={this.state.niche.length === 0 || this.state.language.length === 0 || this.state.numberCategories === 0}
              >
                Generate niche categories
              </Button>
            </Paper>
          </Grid>
        </Grid>
        {this.state.pendingNiches && this.state.pendingNiches.length > 0 && <Grid container spacing={0} justifyContent='center' style={{paddingTop: 80}}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant='h2' align='center'>
              Pending Niches
            </Typography>
            {this.state.pendingNiches.map((pending, nicheIndex) => (
              <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15, marginBottom: 20}}
              key={nicheIndex}
              >
                <Typography variant='body1'>
                  Niche: {pending.niche}
                </Typography>
                <Typography variant='body1'>
                  AI Instance: {__.cfg('aiInstances')[pending.aiInstance]}
                </Typography>
                <Typography variant='body1'>
                  WP Instance: {__.getJsonSto('core').wpInstance[pending.wpInstance]}
                </Typography>
                <Typography variant='body1'>
                  WP Article Status: {pending.wpArticleStatus}
                </Typography>
                <Typography variant='body1'>
                  Use Clickbait: {pending.useClickbait === 0 ? 'No' : 'Yes'}
                </Typography>
                <Typography variant='body1' style={{paddingBottom: 20}}>
                  Language: {pending.language}
                </Typography>
                
                <Autocomplete
                  multiple
                  limitTags={30}
                  id="pending.niche-categories"
                  options={pending.categories}
                  getOptionLabel={(option) => option.category + ' | ' + option.translation}
                  defaultValue={pending.categories}
                  onChange={(event, newValue) => {
                    const updatedPendingNiches = JSON.parse(JSON.stringify(this.state.pendingNiches)) // TODO YOLO
                    updatedPendingNiches[nicheIndex].categories = newValue
                    this.setState({selectedNiches: updatedPendingNiches})
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Niche Categories" placeholder="Categories" />
                  )}
                  style={{marginBottom: 20}}
                />
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.postTopic(nicheIndex)}
                  // disabled={this.state.niche.length === 0 || this.state.language.length === 0 || this.state.numberCategories === 0}
                >
                  Generate topics
                </Button>
              </Paper>
            ))}
          </Grid>
        </Grid>}
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 40, paddingBottom: 40}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Button
              fullWidth
              // variant='contained'
              // color='primary'
              onClick={this.handleGoTopic}
            >
              See pending topics
            </Button>
          </Grid>
        </Grid>
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(NicheView)
