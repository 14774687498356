import packageJson from '../package.json'
const d = {
  isDev: process.env.NODE_ENV === 'development'
}

Object.assign(d, {
  version: packageJson.version,
  apiUrl: 'https://api.creativeblogai.com/v1',
  homeUrl: 'https://creativeblogai.com',
  lstMax: 50,
  maxName: 35,
  maxLow: 25,
  maxElip: 5,
  maxHigh: 100,
  minPw: 8,
  maxPw: 36,
  tsYearFormat: 'DD-MM-YYYY',
  tsDateFormat: 'DD-MM-YYYY HH:mm',
  tsDateFormatDetail: 'DD-MM-YYYY HH:mm:ss',
  tsFormat: 'HH:mm',
  tsFormatDetail: 'HH:mm:ss',
  aiInstances: [
    'OpenAi v3.5 Turbo',
    'OpenAi v4 Turbo'
  ]
})

const cfg = key => (key == null ? d : d[key])
export default cfg
