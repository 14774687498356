import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme } from './view/Style';
import Core from './logic/Core';
import DashView from './view/Dash';
import CsvGen from './view/CsvGen';
import BuddySellyView from './view/BuddySelly';
import SekloanView from './view/Sekloan';
import NicheView from './view/Niche';
import TopicView from './view/Topic';
import LoginView from './view/Login';
import UserView from './view/User';
import TopicalMapView from './view/TopicalMap';

const cx = {};
cx.core = new Core(cx);

const login = (props) => {
  props.cx = cx;
  return cx.core.init() ? <Redirect to="/dash" /> : <LoginView {...props} />;
};

const AuthRoute = ({ component: Component, ...args }) => (
  <Route
    {...args}
    render={(props) => {
      props.cx = cx;
      return cx.core.init() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

const Routes = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact render={login} />
        
        <AuthRoute path="/dash" exact component={DashView} />
        <AuthRoute path="/niche" exact component={NicheView} />
        <AuthRoute path="/topic" exact component={TopicView} />
        
        {/* client pages */}
        <AuthRoute path="/csvgen" exact component={CsvGen} />
        <AuthRoute path="/buddyselly" exact component={BuddySellyView} />
        <AuthRoute path="/sekloan" exact component={SekloanView} />
        <AuthRoute path="/topicalmap" exact component={TopicalMapView} />

        <AuthRoute path="/user" exact component={UserView} />

        <Redirect to="/dash" />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
);

const root = createRoot(document.getElementById('root'));
root.render(<Routes />);

serviceWorkerRegistration.register();
