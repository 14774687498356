import React from 'react'
// import { Redirect } from 'react-router-dom'
// import Box from '@mui/material/Box'
// import * as mo from 'moment'
import { LinearProgress, Paper, Grid, Typography, TextField, Button, Autocomplete } from '@mui/material'
import RttIcon from '@mui/icons-material/Rtt'
import { withStyles } from '@mui/styles'
import { ModalCust, TopBar, Snack } from './Lib'
// import { styleGuide } from './Style'
import __ from '../util'

class TopicView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.reset = {
      loading: false,
      snack: undefined,
      pendingTopics:[],
      nicheKeys: [],
      selectedTopicsPerNiche: []
    }
    this.state = {
      ...this.reset
    }
    this.getPendingTopics = this.getPendingTopics.bind(this)
    this.postArticle = this.postArticle.bind(this)
    this.handleGoNiche = () => this.props.history.push('/niche')
  }

  async postArticle (nicheKey) {
    this.setState({ loading: true })
    try {
      await this.cx.topic.postArticle({
        niche: this.state.pendingTopics[nicheKey][0].niche,
        category: this.state.pendingTopics[nicheKey][0].category,
        aiInstance: this.state.pendingTopics[nicheKey][0].aiInstance,
        wpInstance: this.state.pendingTopics[nicheKey][0].wpInstance,
        wpArticleStatus: this.state.pendingTopics[nicheKey][0].wpArticleStatus,
        useClickbait: this.state.pendingTopics[nicheKey][0].useClickbait,
        pendingTopics: this.state.pendingTopics[nicheKey],
        selectedTopicsPerNiche: this.state.selectedTopicsPerNiche[nicheKey],
        email: __.getJsonSto('core').loginemail // TODO move to token
      })

      this.setState({...this.reset, snack: 'Generating articles in background...'})

      this.getPendingTopics()
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ loading: false, err: emsg })
      throw e
    }
  }


  async getPendingTopics () {
    this.setState({ loading: true })
    let pendingTopics
    try {
      [pendingTopics] = await Promise.all([
        this.cx.topic.getPending({email: __.getJsonSto('core').loginemail})
      ])
      const nicheKeys = Object.keys(pendingTopics || []);
      this.setState({
        loading: false,
        pendingTopics,
        nicheKeys,
        selectedTopicsPerNiche: pendingTopics
      })
    } catch (e) {
      if (__.cfg('isDev')) throw e
      const emsg = e.message
      __.clearSto()
      this.setState({ err: emsg })
      throw e
    }
  }

  async componentDidMount () {

    Object.assign(this, __.initView(this, 'topic'))

    await Promise.all([
     this.getPendingTopics()
    ])
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else if (this.state.loading) {
      return <LinearProgress />
    } else {
      return <div>
        {this.state.snack && (
          <Snack
            msg={this.state.snack}
            onClose={() => this.setState({ snack: undefined })}
          />
        )}
        <TopBar title noTopic />
        {this.state.nicheKeys.length === 0 && <Grid container spacing={0} justifyContent='center' style={{paddingTop: 80}}>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Typography variant='h2' align='center'>
                Empty...
              </Typography>
              <Typography variant='body1' align='center'>
                Please create a niche first
              </Typography>
            </Grid>
          </Grid>}
        {this.state.nicheKeys.length > 0 && <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant='h2' align='center'>
              Topics
              <RttIcon />
            </Typography>
            {this.state.nicheKeys.map((nicheKey, index) => (
              <Paper
              // className={this.props.classes.paperStyle}
              elevation={10}
              style={{padding: 15, marginBottom: 20}}
              key={index}
              >
                <Typography variant='body1'>
                  Niche: {this.state.pendingTopics[nicheKey][0].niche}
                </Typography>
                <Typography variant='body1'>
                  Category: {this.state.pendingTopics[nicheKey][0].category}
                </Typography>
                <Typography variant='body1'>
                  AI Instance: {__.cfg('aiInstances')[this.state.pendingTopics[nicheKey][0].aiInstance]}
                </Typography>
                <Typography variant='body1'>
                  WP Instance: {__.getJsonSto('core').wpInstance[this.state.pendingTopics[nicheKey][0].wpInstance]}
                </Typography>
                <Typography variant='body1'>
                  WP Article Status: {this.state.pendingTopics[nicheKey][0].wpArticleStatus}
                </Typography>
                <Typography variant='body1'>
                  Use Clickbait: {this.state.pendingTopics[nicheKey][0].useClickbait === 0 ? 'No' : 'Yes'}
                </Typography>
                <Typography variant='body1' style={{paddingBottom: 20}}>
                  Language: {this.state.pendingTopics[nicheKey][0].language}
                </Typography>
                <Autocomplete
                  multiple
                  limitTags={100}
                  id="niche-topics"
                  options={this.state.pendingTopics[nicheKey]}
                  getOptionLabel={(option) => option.topic + ' | ' + option.topicTrans}
                  defaultValue={this.state.pendingTopics[nicheKey]}
                  onChange={(event, newValue) => {
                    const updatedSelectedTopicsPerNiche = JSON.parse(JSON.stringify(this.state.pendingTopics)) // TODO YOLO
                    updatedSelectedTopicsPerNiche[nicheKey] = newValue
                    this.setState({selectedTopicsPerNiche: updatedSelectedTopicsPerNiche})
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Articles" placeholder="Article" />
                  )}
                  style={{marginBottom: 20}}
                />
                
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  // className={this.props.classes.loginButton}
                  onClick={async event => this.postArticle(nicheKey)}
                  disabled={this.state.selectedTopicsPerNiche[nicheKey].length === 0}
                >
                  Generate Articles
                </Button>
              </Paper>
            ))}
          </Grid>
        </Grid>}
        <Grid container spacing={0} justifyContent='center' style={{paddingTop: 40, paddingBottom: 40}}>
          <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Button
              fullWidth
              // variant='contained'
              // color='primary'
              onClick={this.handleGoNiche}
            >
              Back to niches
            </Button>
          </Grid>
        </Grid>
      </div>
    }
  }
}

export default withStyles({
    // colorFormat
  })(TopicView)
