import React from 'react'
import Paper from '@mui/material/Paper'
import { ArrowBack } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { withStyles } from '@mui/styles'
import { TopBar, Snack, ModalCust } from './Lib'
import {
  styleGuide,
  paperStyle
} from './Style'
import __ from '../util'

class UserView extends React.Component {
  constructor (props) {
    super(props)
    this.cx = props.cx
    this.widthType = props.width
    this.state = {
      loginemail: __.getJsonSto('core').loginemail,
      group: __.getJsonSto('core').group
    }
    this.handleGoBack = () => {
        this.props.history.push('/dash')
    }

  }

  async componentDidMount () {
    // set body bg
    // document.body.style.backgroundColor = styleGuide.backgroundDark

    Object.assign(this, __.initView(this, 'user'))
  }

  render () {
    if (this.state.err) {
      return (
        <ModalCust
          actions={[
            {
              lbl: 'Go to login',
              onClick: () => this.props.history.push('/login')
            }
          ]}
        >
          {this.state.err}
        </ModalCust>
      )
    } else {
      return (
        <div>
          {this.state.snack && (
            <Snack
              msg={this.state.snack}
              onClose={() => this.setState({ snack: null })}
            />
          )}
          <TopBar
            noUser
            noNiche
            noDash
            noTopic
            iconLeft={<ArrowBack />}
            onClickLeft={this.handleGoBack}
            midTitle='User'
          />
          <Grid container spacing={0} justifyContent='center' style={{paddingTop: 20}}>
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <Paper
                // className={this.props.classes.paperStyle}
                elevation={10}
                style={{padding: 15}}
              >
                <Typography variant='body1'>Email {this.state.loginemail}</Typography>
                <Typography variant='body1'>Group {this.state.group}</Typography>
                <Typography variant='body1'>Version {__.cfg('version')}</Typography>

                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => {
                    this.cx.core.clear()
                    this.props.history.push('/login')
                  }}
                  style={{ marginTop: '60px' }}
                >
                  Logout
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )
    }
  }
}

export default
  withStyles({
    paperStyle
  })(UserView)
